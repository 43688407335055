<template>
  <div class="p-3">
        <template v-if="step === 0">
          <BasicInstructions v-bind:instruction_text="gettext('Now please select the name of each person shown.')"
                             v-on:next="incrementStep"
                             :display_bottom_button="true"
                             :left_text="is_mobile"
          ></BasicInstructions>
        </template>
        <template v-if="step === 1">
          <div class="">
              <div class="justify-content-center text-center my-2">
                <p>{{ gettext('What is the name of this person?')}}</p>
              </div>
              <BasicStimuliImage :custom_classname="is_mobile ? 'img-stimuli-sm' : ''" :image_url="questions[currentQuestion].target_image" :id="`image-${questions[currentQuestion].question_id}`" :key="`fname-match-${questions[currentQuestion].question_id}-${questions[currentQuestion].ordering_id}`"></BasicStimuliImage>
          </div>
          <div class="d-flex align-items-stretch justify-content-center my-4">
                <button class="btn btn-lg btn-primary mx-3" ref="name-btn-1" v-on:click="buttonCallback(nameOrder[0])">{{nameOrder[0]}}</button>
                <button class="btn btn-lg btn-primary mx-3" ref="name-btn-2" v-on:click="buttonCallback(nameOrder[1])">{{ nameOrder[1] }}</button>
                <button class="btn btn-lg btn-primary mx-3" ref="name-btn-3" v-on:click="buttonCallback(nameOrder[2])">{{ nameOrder[2] }}</button>
          </div>
        </template>
    </div>
</template>

<script>
import BasicInstructions from "@/components/BasicInstructions";
import BasicStimuliImage from "@/components/BasicStimuliImage";
import timing from "@/timing";
export default {
  name: "FnameMatchingPhase",
  components: {BasicInstructions, BasicStimuliImage},
  props: ["questions", "is_mobile"],
  mixins: [timing],
  mounted: function() {
    // set the name order for the first question, shuffle it
    this.nameOrder = [this.questions[0].target_name, this.questions[0].foil_names[0], this.questions[0].foil_names[1]]
    this.durstenfeldShuffle(this.nameOrder)
    this.$nextTick(function() {
      this.recordStartTime();
    })
  },
  data: function() {
    return {
      time_start: 0,
      time_end: 0,
      step:0,
      selectedResponse: null,
      currentQuestion: 0,
      currentImage: null,
      complete: false,
      responses: [],
      nameOrder: [],
      averages: {},
      score: 0,
    }
  },
  methods: {
    incrementStep: function (event) {
      this.step++;
      this.recordStartTime()
    },

    incrementQuestion: function (event) {
      window.scrollTo(0,0)
      this.currentQuestion++;
      this.recordStartTime()
      // If we are out of questions, move to the next phase
      if (this.currentQuestion >= this.questions.length) {
        this.complete = true;
        this.averages = this.calculateAvgs()
        this.$emit('complete', this.responses);
        this.$emit('submission', this.createResult())
        return
      }
      // Set the name array to the next question, shuffle it
      this.nameOrder = [this.questions[this.currentQuestion].target_name, this.questions[this.currentQuestion].foil_names[0], this.questions[this.currentQuestion].foil_names[1]]
      this.durstenfeldShuffle(this.nameOrder)
    },
    // Modifies the original array
    durstenfeldShuffle: function(arr) {
      for(let i = arr.length -1; i>0; i--) {
        const j = Math.floor(Math.random() *(i+1));
        [arr[i], arr[j]] = [arr[j], arr[i]]
      }
    },

    buttonCallback: function(value) {
      this.recordEndTime()
      this.selectedResponse = value
      this.responses.push({
        "name": this.questions[this.currentQuestion].name,
        'question_id': this.questions[this.currentQuestion].question_id,
        'response': this.selectedResponse,
        'correct': this.selectedResponse === this.questions[this.currentQuestion].target_name,
        'response_time': this.getTimeDiff()
      })
      this.blurAllButtons()
      this.incrementQuestion()
    },
    calculateAvgs: function() {
      let cumulative_corr_rt = 0
      let cumulative_incorr_rt = 0
      let total_corr_count = 0
      let total_incorrect_count = 0
      this.responses.forEach((response) => {
        if(response.correct) {
          cumulative_corr_rt += response.response_time
          total_corr_count += 1
        }
        else {
          cumulative_incorr_rt += response.response_time
          total_incorrect_count +=1
        }
      })
      let avg_corr_rt = total_corr_count > 0 ? cumulative_corr_rt / total_corr_count : 0
      let avg_incorr_rt = total_incorrect_count > 0 ?  cumulative_incorr_rt / total_incorrect_count: 0
      this.score = total_corr_count
      return {
        "avg_corr_rt": avg_corr_rt,
        "avg_incorr_rt": avg_incorr_rt,
      }
    },

    blurAllButtons: function() {
      this.$refs["name-btn-1"].blur()
      this.$refs["name-btn-2"].blur()
      this.$refs["name-btn-3"].blur()
    },

    createResult: function() {
      return {
        "user_results": this.responses,
        "averages": this.averages,
        "score": this.score
      }
    }

  }
}
</script>

<style scoped>

</style>